<template>
  <v-container>
    <v-row style="margin-bottom:20px !important; word-wrap: break-word !important;">
      <v-col>
        <v-card class="pa-1 mb-2" elevation="12">
          <v-row>
            <!-- Left-hand side with written information -->
            <v-col cols="12" md="6">
              <img src="/learnbread-primary-logo-removebg.png" alt="Learnbread Logo" class="logo rotating-logo" />
              <v-card-title class="headline" style="word-break: break-all !important;">{{ title }}</v-card-title>
              <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
              <v-card-text>
                <p>
                  Learnbread is the cutting-edge platform designed exclusively for the Mind Challenge Industry.<br/> <br/>
                  Join the community where mind challenge enthusiasts converge to challenge themselves,
                  learn new strategies, and celebrate the joy of solving intricate challenges together.
                </p>
                <p v-if="loggedIn">
                  <v-btn to="/home" class="primary">View Events</v-btn>
                </p>
                <p v-else>
                  <v-btn to="/login" class="primary">Get Started</v-btn>
                </p>
              </v-card-text>
            </v-col>

            <!-- Right-hand side with carousel of images -->
            <v-col cols="12" md="6" class="carousel-div">
              <!-- Add your carousel component here -->
              <v-carousel 
                cycle
                :interval="5000"
                :show-arrows="false"
                hide-delimiters
                class="mobile-carousel"
              >
                <v-carousel-item v-for="(item, index) in carouselItems" :key="index">
                  <img :src="item.src" :alt="item.alt" class="carousel-image">
                </v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>
        </v-card>
        <v-expansion-panels>
          <!-- Key Features panel -->
          <v-expansion-panel>
            <v-expansion-panel-header><h3>Key Features</h3></v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <!-- Loop through features -->
                <v-col 
                  class="pb-0"
                  cols="12"
                  md="4"
                  v-for="(feature, index) in features"
                  :key="index">
                  <h6>
                    <v-icon color="primary">mdi-food</v-icon>
                    {{ feature.title }}
                  </h6>
                  <p>{{ feature.description }}</p>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header><h3>SDG Connections</h3></v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <!-- Loop through SDG connections -->
                <v-col 
                  class="pb-0"
                  cols="12"
                  md="4"
                  v-for="(connection, index) in sdgConnections"
                  :key="index">
                  <h6>
                    <v-icon color="primary">mdi-food</v-icon>
                    {{ connection.goal }}
                  </h6>
                  <p>{{ connection.description }}</p>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <!-- Add section for community links -->
    <h2 class="headline mb-2">Community Links:</h2>
    <v-row style="margin-bottom: 150px !important;">
      <v-col cols="12">

        <!-- WhatsApp Message -->
        <v-btn
          outlined 
          color="primary"
          href="https://wa.me/2348162037218"
          target="_blank"
          class="mb-2">
          <v-icon left>mdi-whatsapp</v-icon>
          Send Us a Message
        </v-btn> &nbsp; &nbsp;
        
        <!-- Facebook -->
        <v-btn 
          outlined
          color="primary"
          href="https://www.facebook.com/LearnbreadHQ"
          target="_blank"
          class="mb-2">
          <v-icon left>mdi-facebook</v-icon>
          Follow us on Facebook Page
        </v-btn> &nbsp; &nbsp;
        
        <!-- Facebook -->
        <v-btn 
          outlined
          color="primary"
          href="https://www.facebook.com/groups/learnbread"
          target="_blank"
          class="mb-2">
          <v-icon left>mdi-facebook</v-icon>
          Join Facebook Community
        </v-btn> &nbsp; &nbsp;

        <!-- WhatsApp Channel -->
        <v-btn 
          class="mb-2"
          outlined
          color="primary"
          href="https://whatsapp.com/channel/0029VaIYIyZEVccJAX7rZg22"
          target="_blank">
          <v-icon left>mdi-whatsapp</v-icon>
          Follow us on WhatsApp Channel
        </v-btn>
        
        <!-- WhatsApp Channel -->
        <v-btn 
          class="mb-2" 
          outlined
          color="primary"
          href="https://t.me/+GLSUCtGJc48IZs88"
          target="_blank">
          <v-icon left>mdi-telegram</v-icon>
          Join Telegram Community
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    loggedIn() {
      return this.$store.getters['auth/isLoggedIn']
    },
    userInfo() {
      return this.$store.getters['auth/userInfo']
    }
  },
  data() {
    return {
      title: 'Welcome to Learnbread!',
      subtitle: 'Embark on a journey of intellectual exploration with Learnbread',
      carouselItems: [
        { src: '/images/events/LLBF1.0/learnbreaddotcom.jpg', alt: 'Image 1' },
        { src: '/images/events/LLBF1.0/Learnbread1.jpg', alt: 'Image 2' },
        { src: '/images/events/LLBF1.0/learnbreadpuzzle.jpg', alt: 'Image 3' },
        { src: '/images/events/LLBF1.0/Learnbread2.jpg', alt: 'Image 4' },
        { src: '/images/events/LLBF1.0/learnbreadpuzzle1.jpg', alt: 'Image 3' },
        { src: '/images/events/LLBF1.0/Learnbread3.jpg', alt: 'Image 5' },
        { src: '/images/events/LLBF1.0/learnbreadpuzzle2.jpg', alt: 'Image 3' },
        { src: '/images/events/LLBF1.0/Learnbread4.jpg', alt: 'Image 6' },
        { src: '/images/events/LLBF1.0/learnbreadpuzzle3.jpg', alt: 'Image 3' },
        { src: '/images/events/LLBF1.0/Learnbread5.jpg', alt: 'Image 7' }
      ],
      features: [
        {
          title: 'Diverse Challenge Categories',
          description: 'Learnbread offers a wide array of mind challenges, ranging from logic puzzles and riddles to spatial reasoning challenges. Community owners can customize and create their own challenges, ensuring a varied and dynamic experience for participants.'
        },
        {
          title: 'Interactive Learning Modules',
          description: 'In addition to challenges, Learnbread integrates interactive learning modules to enhance participants understanding of different challenge-solving techniques. This feature makes it an educational platform where users can develop and sharpen their cognitive skills.'
        },
        {
          title: 'Community Building',
          description: 'Foster a sense of camaraderie among community members through Learnbreads community building tools. Participants can collaborate, discuss strategies, and share their experiences, creating a vibrant and supportive challenge-solving community.'
        },
        {
          title: 'Leaderboards and Rewards',
          description: 'Keep participants motivated with real-time leaderboards showcasing top performers. Learnbread allows community owners to implement reward systems, encouraging healthy competition and recognizing the achievements of challenge enthusiasts.'
        },
        {
          title: 'Customizable Challenges',
          description: 'Tailor challenges to suit the preferences and skill levels of your community. Whether you\'re organizing beginner-friendly challenges or advanced brain teasers, Learnbread provides the flexibility to create challenges that resonate with your audience.'
        },
        {
          title: 'Analytics Dashboard',
          description: 'Gain insights into community engagement and challenge performance through Learnbread analytics dashboard. Track participation metrics identify popular challenge types and make data-driven decisions to continuously improve the challenge experience.'
        },
        {
          title: 'User-Friendly Interface',
          description: 'Learnbread boasts an intuitive and user-friendly interface, ensuring that both community owners and participants can easily navigate and enjoy the platform. Seamless design contributes to an enjoyable user experience.'
        }
      ],
      sdgConnections: [
        {
          goal: 'Quality Education (SDG 4)',
          description: 'Learnbread provides an educational platform that fosters cognitive skills, problem-solving abilities, and continuous learning. By offering diverse challenges and interactive learning modules, the platform contributes to promoting quality education.'
        },
        {
          goal: 'Decent Work and Economic Growth (SDG 8)',
          description: 'Through partnerships, collaborations, and community building, Learnbread has the potential to create economic opportunities within the Mind Challenge Industry. This may include opportunities for content creators, educators, and those involved in challenge creation.'
        },
        {
          goal: 'Industry, Innovation, and Infrastructure (SDG 9)',
          description: 'As a pioneering platform in the Mind Challenge Industry, Learnbread contributes to fostering innovation and building a new sector within the broader educational and entertainment industry.'
        },
        {
          goal: 'Reduced Inequalities (SDG 10)',
          description: 'Learnbread\'s customizable challenges for all skill levels and its commitment to inclusivity contribute to reducing inequalities in access to educational and recreational opportunities. It provides a platform where individuals from diverse backgrounds can engage and thrive.'
        },
        {
          goal: 'Partnerships for the Goals (SDG 17)',
          description: 'Through collaborations with influencers, thought leaders, and organizations, Learnbread exemplifies the spirit of partnerships for sustainable development. These partnerships can amplify the impact of the platform and contribute to achieving shared goals.'
        }
      ]
    }
  }
}
</script>

<style scoped>
  .headline {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .logo {
    width: 50px;
    height: auto;
    margin-bottom: 20px;
  }

  .primary {
    color: #fff;
    background-color: #4CAF50;
  }

  /* Add these styles to disable ellipsis */
  .v-list-item-title-custom,
  .v-list-item-subtitle-custom {
    white-space: normal;
  }
  .carousel-image {
    width: 100%; /* Ensure the image takes full width of the carousel */
    height: auto; /* Maintain aspect ratio */
  }

  @media only screen and (max-width: 600px) {
    .mobile-carousel {
      max-height: 300px !important;
    }
  }

  /* Add animation for rotating logo */
  .rotating-logo {
    animation: rotate 5s linear infinite; /* Rotate the logo continuously */
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg); /* Start rotating from 0 degrees */
    }
    to {
      transform: rotate(360deg); /* Rotate to 360 degrees */
    }
  }
</style>
